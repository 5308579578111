import React, { useEffect, useState } from "react";
import DataTable from "../../Components/ui/dataTable";
import axios from "../../Axios";
import CustomeBox from "../../Components/ui/CustomeBox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";

const headCells = [
  { id: "Date", label: "Date" },
  { id: "Day", label: "Day" },
  { id: "Description", label: "Description" },
  // { id: "Mandatory", label: "Mandatory" },
];

const HolidayList = () => {
  const [holidayList, setHolidayList] = useState([]);

  const [dataFound, setDataFound] = useState(true);

  useEffect(() => {
    try {
      const HolidayList = async () => {
        const response = await axios.get("/getHoliday");
        if (response.data.data.length === 0) {
          setDataFound(false);
        } else {
          setDataFound(true);
          setHolidayList(response.data.data);
        }
      };
      HolidayList();
    } catch (err) {
      toast.error(err, Toaster);
    }
  }, []);

  return (
    <>
      <ToastContainer />
       <CustomeBox BoxHeader="Holiday List-2025">
        <DataTable
          records={holidayList}
          headCells={headCells}
          enablePagination={true}
          dataFound={dataFound}
        />
      </CustomeBox>
    </>
  );
};

export default HolidayList;
